.promotionalBannerModal {
  background-color: #0f0c14;
  color: white;
  width: 100%;
  height: 100%;
}

.promotionalBannerModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.promotionalBannerModalDescription {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.promotionalBannerModalButton a {
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & img {
    width: 20px;
    height: 20px;
  }
}

.promotionalBannerModalButton a:hover {
  text-decoration: none;
  transition: all 0.4s ease;
  transform: scale(1.05);
}

.promotionalBannerModalNoShow {
  margin-top: 20px;
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent !important;
  border: 1px solid #000000;
  padding: 10px 20px;
  border-radius: 8px;
}

.promotionalBannerModalNoShow a:hover {
  text-decoration: none;
  transition: all 0.4s ease;
  transform: scale(1.05);
}
