@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
  color: var(--matterColorDark);
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.itemLabel {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorDark);
  flex: 1;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorDark);
  margin: 0 0 0 10px;
}

.bondDisclaimer {
  width: 100%;
  margin-top: 8px;
  line-height: 1.5;
  font-size: 14px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 8px 0;
  }
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorDark);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  color: var(--matterColorDark);
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.bookingExtended {
  margin: 30px 0 20px 0;

  & > h2 {
    margin: 0;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: var(--matterColorDark);
  }
}

.exceedsCharge {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 800;
}
